import { Box, useTheme } from '@mui/material';
import SidebarMenu from './Menu';
import SidebarTopSection from './TopSection';
import SidebarBottomSection from './BottomSection';
import { CustomDivider } from './styles';
import Favourites from './Favourites';
import { useSelector } from 'src/redux/store';

function Sidebar() {
  const theme = useTheme();
  const { userFavourites } = useSelector((state) => state.data);

  return (
    <>
      <Box
        maxHeight={'100vh'}
        height={'100vh'}
        sx={{
          backgroundColor: theme.sidebar.background
        }}
        display="flex"
        flexDirection="column"
      >
        <Box flex={'1'} display={'flex'} flexDirection={'column'}>
          <SidebarTopSection />
        </Box>
        {userFavourites?.length ? <Favourites /> : null}
        <CustomDivider />
        <SidebarMenu />
        <SidebarBottomSection />
      </Box>
    </>
  );
}

export default Sidebar;
