import { StringKeys } from './base';

export enum FilterElementTypes {
  Select = 'select',
  AutoComplete = 'autocomplete',
  MultiAutoComplete = 'multiautocomplete',
  Text = 'text',
  SearchAutoComplete = 'search_autocomplete'
}

export type SelectItem = {
  id: number;
  name: string;
} & StringKeys;

export type FilterSelect = {
  id: number;
  name: string;
  title?: string;
} & StringKeys;

export type FilterComponents = {
  name: string;
  type: FilterElementTypes;
  datasource?: FilterSelect[];
  filteredDatasource?: FilterSelect[] | null; //used for initially rendering filteredData. onChange in GridFilter will use actual datasource
  placeholder?: string;
  color?: string;
  defaultValue?: any;
  dataField: string;
  elementWidth?: number;
  showIcon?: boolean;
  showDivider?: boolean;
  disableSearch?: boolean;
  valueKey?: string;
  searchApi?: any;
  labelKey?: string;
  showLabel?: boolean;
  label?: string;
};

export interface FilterDatasource {
  [z: string]: FilterSelect[];
}

export interface IBreadcrumbItem {
  id: string;
  name: string;
  webUrl?: string;
  disabled?: boolean;
  display_name?: string;
}
