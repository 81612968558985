import { createSlice } from '@reduxjs/toolkit';
type onboardingType = {
  isOnboarding: boolean;
  stepCount?: number;
  stepsContext?: string;
  updateOnboardingStep: boolean;
};
const initialState: onboardingType = {
  isOnboarding: false,
  stepCount: 0,
  stepsContext: 'chat',
  updateOnboardingStep: false
};

const onboardingSlice = createSlice({
  name: 'context',
  initialState,
  reducers: {
    toggleOnboarding: (state) => {
      state.isOnboarding = !state.isOnboarding;
    },
    updateOnboarding: (state, { payload }) => {
      state.isOnboarding = payload?.isOnboarding;
      state.stepCount = payload?.stepCount || 0;
      state.stepsContext = payload?.stepsContext || 'chat';
      state.updateOnboardingStep = payload?.updateOnboardingStep;
    }
  }
});

export const { toggleOnboarding, updateOnboarding } = onboardingSlice.actions;

export const reducer = onboardingSlice.reducer;
