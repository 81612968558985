import { FC, useState } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import PropTypes from 'prop-types';
import { Box, Button, ListItem } from '@mui/material';
import CustomListItemText from 'src/layouts/AccentHeaderLayout/Sidebar/ListItemText';
import { TFunction } from 'react-i18next';

interface SidebarFavouriteItemProps {
  link?: string;
  icon?: any;
  active?: boolean;
  name: string;
  id: number;
  workspaceName?: string;
  handleStartChatClick: (
    id: number,
    name: string,
    workspaceName?: string
  ) => void;
  t: TFunction<'translation', undefined>;
}

const SidebarFavouriteItem: FC<SidebarFavouriteItemProps> = ({
  link,
  icon: Icon,
  active,
  name,
  id,
  handleStartChatClick,
  workspaceName,
  t,
  ...rest
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleStartChat = (e: any) => {
    e.preventDefault();
    handleStartChatClick?.(id, name, workspaceName);
  };

  return (
    <ListItem
      component="div"
      key={name}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      {...rest}
      disablePadding
    >
      <Button
        disableRipple
        activeClassName="Mui-active"
        component={RouterLink}
        to={link}
        sx={{
          height: '40px'
        }}
        startIcon={
          Icon && (
            <Icon
              style={{
                fontSize: 16
              }}
            />
          )
        }
      >
        <CustomListItemText
          primaryText={name}
          sx={{ textOverflow: 'ellipsis' }}
        />
        <Box>
          {isHovered && (
            <Button
              title={t(T.startChat)}
              onClick={handleStartChat}
              size="small"
              variant="contained"
              sx={{
                fontSize: '12px',
                px: 0.8,
                py: 0.5
              }}
            >
              {t(T.startChat)}
            </Button>
          )}
        </Box>
      </Button>
    </ListItem>
  );
};

SidebarFavouriteItem.propTypes = {
  active: PropTypes.bool,
  link: PropTypes.string,
  icon: PropTypes.elementType,
  name: PropTypes.string.isRequired
};

SidebarFavouriteItem.defaultProps = {
  active: false
};

export default SidebarFavouriteItem;
