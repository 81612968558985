import { FC, ReactNode, useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { AzureWebPubSubUserProvider } from 'src/contexts/AzureWebPubSubUserContext';
import { fetchADAccessToken, setAdToken } from 'src/keycloak';
import { RootState, useDispatch, useSelector } from 'src/redux/store';
import { ALERT, USER_TYPE } from 'src/types/enum';
import { setAlert } from 'src/redux/slices/snackbar';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import { useTranslation } from 'react-i18next';
import logger from 'src/utils/logger';
interface AuthenticatedProps {
  children: ReactNode;
}

const Authenticated: FC<AuthenticatedProps> = (props: { children: any }) => {
  const { children } = props;
  const location = useLocation();
  const [requestedLocation, setRequestedLocation] = useState<string | null>(
    null
  );
  const { isAuthorized, user } = useSelector((state) => state.auth);
  const { sharePointProviderId } = useSelector(
    (state: RootState) => state.data
  );
  const [adTokenExpiryTime, setADTokenExpiryTime] = useState<number>();
  const dispatch = useDispatch();
  const { t }: { t: any } = useTranslation();

  const updateADAccessToken = async () => {
    const response = await fetchADAccessToken();
    if (response) {
      setADTokenExpiryTime(response.accessTokenExpiration);
      setAdToken(response.access_token);
    } else {
      dispatch(
        setAlert({
          msg: t(T.sharePointAuthError),
          type: ALERT.SUCCESS
        })
      );
    }
  };

  useEffect(() => {
    if (adTokenExpiryTime) {
      // Calculate expiry time in milliseconds
      const expiryTimeInMilliseconds = adTokenExpiryTime * 1000;

      // Calculate time difference to refresh token 10 seconds before expiry
      const refreshTime = expiryTimeInMilliseconds - Date.now() - 10000;

      if (refreshTime > 0) {
        // Set timeout to refresh token before expiry
        const timeout = setTimeout(() => {
          logger.log('AD Token will expire soon, fetching new token...');
          updateADAccessToken(); // Fetch token 10 seconds before expiry
        }, refreshTime);

        // Clear timeout on unmount or when tokenExpiry changes
        return () => {
          logger.log(
            'Clearing timeout on unmount or when AD tokenExpiry changes'
          );
          clearTimeout(timeout);
        };
      }
    }
  }, [adTokenExpiryTime]);

  useEffect(() => {
    if (
      isAuthorized &&
      user?.userType === USER_TYPE.AZURE &&
      !!sharePointProviderId
    ) {
      updateADAccessToken();
    }
  }, [isAuthorized, user, sharePointProviderId]);

  if (requestedLocation && location.pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <AzureWebPubSubUserProvider>{children}</AzureWebPubSubUserProvider>;
};

Authenticated.propTypes = {
  children: PropTypes.node
};

export default Authenticated;
