import {
  Box,
  Card,
  Typography,
  Container,
  Button,
  styled
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { ReactComponent as Status404Img } from 'src/assets/status/404.svg';

import { useTranslation } from 'react-i18next';

const MainContent = styled(Box)(
  () => `
    height: 100%;
    display: flex;
    flex: 1;
    overflow: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
);

function Status404() {
  const { t }: { t: any } = useTranslation();

  return (
    <>
      <Helmet>
        <title>Status - 404</title>
      </Helmet>
      <MainContent>
        <Container maxWidth="md">
          <Box textAlign="center">
            <Status404Img
              style={{
                minHeight: '180px'
              }}
            />
            <Typography
              variant="h2"
              sx={{
                my: 2
              }}
            >
              {t("The page you were looking for doesn't exist.")}
            </Typography>
            <Typography
              variant="h4"
              color="text.secondary"
              fontWeight="normal"
              sx={{
                mb: 4
              }}
            >
              {t(
                "It's on us, we moved the content to a different page. The search below should help!"
              )}
            </Typography>
          </Box>
          <Container maxWidth="sm">
            <Card
              sx={{
                textAlign: 'center',
                mt: 3,
                p: 4
              }}
            >
              <Button href="/chat" variant="outlined">
                {t('Go to homepage')}
              </Button>
            </Card>
          </Container>
        </Container>
      </MainContent>
    </>
  );
}

export default Status404;
