import { useCallback, useRef, useState } from 'react';

import {
  Box,
  List,
  ListItem,
  Divider,
  Typography,
  ListItemText,
  Popover,
  styled,
  Button
} from '@mui/material';

import internationalization from 'src/i18n/i18n';
import { useTranslation } from 'react-i18next';
import deFlag from 'country-flag-icons/3x2/DE.svg';
import usFlag from 'country-flag-icons/3x2/US.svg';
import itFlag from 'country-flag-icons/3x2/IT.svg';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import { useUpdateUserPreferencesMutation } from 'src/services/api';
import { updateUserLanguage } from 'src/redux/slices/data';
import { useDispatch, useSelector } from 'src/redux/store';
import CustomListItemText from 'src/layouts/AccentHeaderLayout/Sidebar/ListItemText';
import useMutation from 'src/hooks/useMutation';
import { ErrorContext } from 'src/utils/errorMappings';
import { languages } from './languages';

const SectionHeading = styled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
        padding: ${theme.spacing(2, 2, 0)};
`
);
const ImageWrapper = styled('img')(
  () => `
        width: 20px;
        height: 16px;
        border-radius: 2px;
`
);

function LanguageSwitcher() {
  const { t }: { t: any } = useTranslation();
  const [isOpen, setOpen] = useState<boolean>(false);
  const [updateLanguage] = useMutation({
    api: useUpdateUserPreferencesMutation,
    errorContext: ErrorContext.GENERAL
  });
  const dispatch = useDispatch();
  const { language } = useSelector((state) => state.data);
  const ref = useRef<any>(null);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const switchLanguage = async ({ lng }: { lng: any }) => {
    internationalization.changeLanguage(lng);
    dispatch(updateUserLanguage(lng));
    await updateLanguage({
      params: {
        params: { name: 'lang', v: lng }
      },
      fallbackMsg: T.internalServerError
    });
  };

  const getFlag = useCallback(() => {
    switch (language) {
      case 'en':
      case 'en-US':
      case 'en-GB':
        return <ImageWrapper alt="English" src={usFlag} />;
      case 'it':
        return <ImageWrapper alt="Italian" src={itFlag} />;
      case 'de':
      default:
        return <ImageWrapper alt="Dutch" src={deFlag} />;
    }
  }, [language]);

  return (
    <>
      <Button
        sx={{ padding: '9px 16px' }}
        disableRipple
        fullWidth
        size="small"
        ref={ref}
        onClick={handleOpen}
        startIcon={getFlag()}
      >
        <CustomListItemText primaryText={t(T.languageSwitcher)} />
      </Button>
      <Popover
        disableScrollLock
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        PaperProps={{
          sx: {
            backgroundColor: `primary.main`
          }
        }}
      >
        <Box
          sx={{
            maxWidth: 240
          }}
        >
          <SectionHeading variant="body2">
            {t(T.languageSwitcher)}
          </SectionHeading>
          <List
            sx={{
              p: 2
            }}
            component="nav"
          >
            {languages.map((item, index) => (
              <ListItem
                key={`${item.code}_${index}`}
                className={
                  language === item.code ||
                  (item?.otherCodes?.length &&
                    item.otherCodes.includes(language))
                    ? 'active'
                    : ''
                }
                button
                onClick={() => {
                  switchLanguage({ lng: item.code });
                  handleClose();
                }}
              >
                <ImageWrapper alt={item.name} src={item.flag} />
                <ListItemText
                  sx={{
                    pl: 1
                  }}
                  primary={item.name}
                  primaryTypographyProps={{ color: 'white' }}
                />
              </ListItem>
            ))}
          </List>
          <Divider />
        </Box>
      </Popover>
    </>
  );
}

export default LanguageSwitcher;
