import { Box, Button, ButtonTypeMap, Popover, useTheme } from '@mui/material';
import { FC, useRef, useState, useCallback } from 'react';
import TooltipOnDisabled from '../TooltipOnDisabled';
import { PopoverElement } from 'src/types/header';
import { ONBOARDING_STEPS_TARGET } from '../UserOnboard/constants';

type Props = Readonly<{
  isButtonDisabled: boolean;
  onClick: () => void;
  tooltipMessage: string;
  buttonProps: ButtonTypeMap['props'];
  btnText: string;
  isPopover?: boolean;
  popoverElements?: PopoverElement[];
  elemId?: string;
}>;

const HeaderButton: FC<Props> = ({
  isButtonDisabled,
  onClick,
  tooltipMessage,
  buttonProps,
  btnText,
  isPopover,
  popoverElements = [],
  elemId = undefined
}) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? 'popover-actions' : undefined;
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const handleOpenPopup = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
    },
    []
  );

  const handleClose = useCallback((e) => {
    e.stopPropagation();
    setAnchorEl(null);
  }, []);

  return (
    <>
      <TooltipOnDisabled title={tooltipMessage} showTooltip={isButtonDisabled}>
        <Button
          id={elemId}
          ref={buttonRef}
          {...buttonProps}
          disabled={isButtonDisabled}
          onClick={isPopover ? handleOpenPopup : onClick}
          sx={{ border: buttonProps.variant === 'outlined' ? '1px solid' : '' }}
        >
          {btnText}
        </Button>
      </TooltipOnDisabled>
      {isPopover && (
        <Popover
          id={id}
          open={open}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          anchorEl={anchorEl}
          onClose={handleClose}
        >
          <Box
            id={ONBOARDING_STEPS_TARGET.DOCUMENT_ADD_MENU_POPOVER}
            textAlign={'left'}
            display={'flex'}
            flexDirection={'column'}
            width={'auto'}
            minWidth={`${buttonRef?.current?.clientWidth}px`}
          >
            {popoverElements.map(
              (
                {
                  disabled,
                  onClick,
                  text,
                  visible,
                  icon: Icon,
                  showTooltip,
                  tooltipMessage,
                  id
                },
                index
              ) => (
                <div key={index} onClick={handleClose}>
                  {visible ? (
                    <TooltipOnDisabled
                      title={tooltipMessage || ''}
                      showTooltip={showTooltip || false}
                    >
                      <Button
                        id={id ? id : undefined}
                        sx={{
                          paddingLeft: '10px',
                          paddingY: 0.25,
                          borderRadius: 0,
                          color: theme.colors.custom.labelColor,
                          fontWeight: 400,
                          justifyContent: 'flex-start',
                          ...(index !== 0 && { borderTop: '1px solid #E5E5E5' })
                        }}
                        disabled={disabled}
                        fullWidth
                        onClick={onClick}
                        startIcon={
                          Icon && (
                            <Icon
                              sx={{
                                paddingLeft: 0.2
                              }}
                            />
                          )
                        }
                      >
                        {text}
                      </Button>
                    </TooltipOnDisabled>
                  ) : null}
                </div>
              )
            )}
          </Box>
        </Popover>
      )}
    </>
  );
};

export default HeaderButton;
