import { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Card,
  Paper,
  Skeleton,
  Typography,
  useTheme
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import PageHeader from 'src/components/PageHeader';
import ProgressBar from 'src/components/ProgressBar';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import {
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  ResponsiveContainer
} from 'recharts';
import {
  useLazyGetProcessedDocumentsQuery,
  useLazyGetUsageQuery
} from 'src/services/api';
import { endOfMonth, format, startOfMonth, subMinutes } from 'date-fns';
import MonthPicker from 'src/components/MonthPicker';
import { useTranslation } from 'react-i18next';
import { StringKeys } from 'src/types/base';
import {
  formatFloatNumber,
  getPercentage,
  sortUsageStats,
  getColor
} from './utils';
import useLazyQuery from 'src/hooks/useLazyQuery';
import { ErrorContext } from 'src/utils/errorMappings';
import { ReactComponent as AIUsageIcon } from 'src/assets/Analytics/ai_usage.svg';
import { ReactComponent as DocUsageIcon } from 'src/assets/Analytics/document_usage.svg';
import { USAGE_STATS_TYPE } from 'src/types/enum';
import CustomChartTooltip from './CustomChartTooltip';

const Usage = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [prevMonth, setPrevMonth] = useState<Date>(new Date());
  const [month, setMonth] = useState<Date>(new Date());
  const [sortedUsageStarts, setSortedUsageStarts] = useState<StringKeys[]>();
  const [
    fetchProcessedDocuments,
    { data: processedDocumentsStats, isFetching: isFetchingProcessedDocuments }
  ] = useLazyQuery({
    api: useLazyGetProcessedDocumentsQuery,
    errorContext: ErrorContext.GENERAL
  });

  const [
    fetchUsageStats,
    {
      data: usageStats,
      isFetching: isFetchingUsageStats,
      isError: isFetchingUsageStatsFailed
    }
  ] = useLazyQuery({
    api: useLazyGetUsageQuery,
    errorContext: ErrorContext.GENERAL
  });

  useEffect(() => {
    fetchProcessedDocuments({ params: undefined });
  }, []);

  useEffect(() => {
    if (month) fetchUsage(month);
  }, [month]);

  useEffect(() => {
    if (usageStats) {
      setSortedUsageStarts([...sortUsageStats(usageStats)]);
    }
  }, [usageStats]);

  useEffect(() => {
    if (isFetchingUsageStatsFailed) setMonth(prevMonth);
  }, [isFetchingUsageStatsFailed]);

  /**
   * +-----------------------------+------------------------------+-----------------------------+------------------------------+
   * |      Current Time           |          Start of Month      |     Converted to UTC        |       Diff in mins           |
   * | UTC => 01 Dec 2023 00:00 am |   01 Dec 2023 00:00 am +0    |    01 Dec 2023 00:00 am     |          0 mins              |
   * | Pak => 01 Dec 2023 05:00 am |   01 Dec 2023 00:00 am +5    |    30 Nov 2023 07:00 pm     |       -300 mins              |
   * | CED => 30 Nov 2023 04:00 pm |   30 Nov 2023 00:00 pm -8    |    01 Nov 2023 08:00 am     |        480 mins              |
   * +-----------------------------+------------------------------+-----------------------------+------------------------------+
   */

  const CustomChartTooltipContent = useCallback(
    (props) => (
      <CustomChartTooltip
        label={props.label}
        active={props.active}
        data={props.payload}
        theme={theme}
        usageStats={usageStats}
      />
    ),
    [theme, usageStats]
  );

  const fetchUsage = useCallback((date: Date) => {
    let startDate = date;
    const offset = date.getTimezoneOffset();
    startDate = subMinutes(startOfMonth(startDate), offset);
    const endDate = subMinutes(endOfMonth(date), offset);
    fetchUsageStats({
      params: {
        start_date: startDate.toISOString(),
        end_date: endDate.toISOString()
      }
    });
  }, []);

  const handleDateChange = useCallback(
    (date: Date) => {
      if (date) {
        setPrevMonth(month);
        setMonth(date);
      }
    },
    [month]
  );

  return (
    <>
      <Helmet>
        <title>
          {T.empowerGPT} - {t(T.usage)}
        </title>
      </Helmet>
      <Box
        sx={{
          maxHeight: '100%',
          overflow: 'auto',
          height: '100%',
          backgroundColor: theme.colors.alpha.white[100]
        }}
        padding={2}
      >
        <PageHeader title={t(T.usage)} actionButtons={[]} />
        <Box sx={{ marginX: 15 }}>
          <Box padding={1}>
            <ProgressBar
              value={getPercentage(
                processedDocumentsStats?.total || 0,
                processedDocumentsStats?.consumed || 0
              )}
              title={t(T.processedDocuments)}
              progressHeader={t(T.overallUsage)}
              unit={processedDocumentsStats?.unit || ''}
              total={processedDocumentsStats?.total || 0}
              consumed={processedDocumentsStats?.consumed || 0}
              showInfoIcon
              infoMsg={t(T.processedDocumentsInfo)}
              creditUsageMsg={t(T.tokenUsageInfo, {
                consumed: formatFloatNumber(
                  processedDocumentsStats?.consumed || 0,
                  0
                ),
                total: formatFloatNumber(
                  processedDocumentsStats?.total || 0,
                  0
                ),
                unit: processedDocumentsStats?.unit
              })}
              loading={isFetchingProcessedDocuments}
              icon={<DocUsageIcon />}
              capitalizeUnits
            />
          </Box>

          <Box padding={1}>
            <ProgressBar
              value={getPercentage(
                usageStats?.total || 0,
                usageStats?.monthly_usage || 0
              )}
              title={t(T.aiUsage)}
              progressHeader={t(T.monthlyUsage)}
              unit={usageStats?.unit || ''}
              consumed={usageStats?.monthly_usage || 0}
              total={usageStats?.total || 0}
              showInfoIcon
              infoMsg={t(T.aiUsageInfo)}
              creditUsageMsg={t(T.creditUsageInfo, {
                consumed: formatFloatNumber(usageStats?.monthly_usage || 0),
                total: formatFloatNumber(usageStats?.total || 0),
                unit: usageStats?.unit
              })}
              dateFilter={
                <MonthPicker
                  value={month}
                  onMonthChange={handleDateChange}
                  loading={isFetchingUsageStats}
                />
              }
              loading={isFetchingUsageStats}
              icon={<AIUsageIcon />}
            />
          </Box>
          <Paper
            sx={{
              padding: 1
            }}
            elevation={0}
          >
            <Card
              sx={{
                borderRadius: '6px',
                border: '1px solid rgba(170, 190, 222, 0.40)',
                padding: 2
              }}
              elevation={0}
            >
              <Box display={'flex'} alignItems={'center'} marginBottom={5}>
                <Typography fontSize={20} fontWeight={500} color="primary">
                  {t(T.dailyUsage)}
                </Typography>
                <Typography
                  fontSize={18}
                  fontWeight={400}
                  sx={(theme) => ({
                    color: theme.colors.custom.iconColor,
                    paddingLeft: 1
                  })}
                >
                  {t(T.credits)}
                </Typography>
              </Box>
              {isFetchingUsageStats ? (
                <Skeleton variant="rectangular" height={400} animation="wave" />
              ) : !usageStats?.daily_usage?.length ? (
                <Typography variant="h5">
                  {t(T.noCreditsUsedThisMonth)}
                </Typography>
              ) : (
                <ResponsiveContainer height={400}>
                  <BarChart data={sortedUsageStarts || []} barSize={12}>
                    <CartesianGrid strokeDasharray="3 3" vertical={false} />
                    <XAxis
                      dataKey="date"
                      tickFormatter={(value) =>
                        format(new Date(value), 'd MMM')
                      }
                      interval={1}
                    />
                    <Tooltip
                      cursor={{ fill: 'transparent' }}
                      content={CustomChartTooltipContent}
                      filterNull
                    />
                    <YAxis />
                    {Object.keys(USAGE_STATS_TYPE).map((type) => (
                      <Bar
                        key={type}
                        dataKey={type}
                        stackId="stack"
                        fill={getColor(type)}
                      />
                    ))}
                  </BarChart>
                </ResponsiveContainer>
              )}
            </Card>
          </Paper>
        </Box>
      </Box>
    </>
  );
};

export default Usage;
