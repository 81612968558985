import { ArticleOutlined } from '@mui/icons-material';
import type { ReactNode } from 'react';
import { USER_APP_PERMISSIONS } from 'src/types/enum';
export interface MenuItem {
  link?: string;
  icon?: ReactNode;
  badge?: string;
  badgeTooltip?: string;

  items?: MenuItem[];
  name: string;
}

export interface MenuItems {
  items: MenuItem[];
  heading: string;
}

const getMenuItems = (userAppPermissions) => {
  if (
    userAppPermissions?.[USER_APP_PERMISSIONS.VIEW_ROOT_FOLDER_CONTENT]?.value
  ) {
    return [
      {
        heading: '',
        items: [
          {
            name: 'Data Library',
            icon: ArticleOutlined,
            link: '/data-library'
          }
        ]
      }
    ];
  }
  return [];
};

export default getMenuItems;
