import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  GlobalSiteInputParams,
  IDriveInputParams,
  IDriveItemInputParams,
  IFolderItemInputParams,
  ISiteInputParams
} from 'src/types/microsoftApi';
import { getUnEncryptedQueryString } from './utils';
import { MICROSOFT_GRAPH_API_URL } from 'src/config';
import { getAdToken } from 'src/keycloak';
import {
  IMgtBaseResponse,
  IMgtFileResponse,
  IMgtFolderResponse,
  IMgtSiteContentResponse,
  IMgtSiteResponse
} from 'src/content/Documents/SharePoint/types';

export const microsoftApi = createApi({
  reducerPath: 'microsoftApi',
  baseQuery: fetchBaseQuery({
    baseUrl: MICROSOFT_GRAPH_API_URL,
    prepareHeaders: (headers) => {
      const token = getAdToken();
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    }
  }),
  endpoints: (builder) => ({
    getAllSites: builder.query<
      IMgtBaseResponse<IMgtSiteResponse>,
      GlobalSiteInputParams
    >({
      async queryFn(
        arg: GlobalSiteInputParams,
        { getState },
        extraOptions,
        fetchWithBQ
      ) {
        let url = `sites?${getUnEncryptedQueryString({
          ...arg
        })}`;
        let result: any = await fetchWithBQ({
          url: url
        });
        return result;
      }
    }),
    getSites: builder.query<
      IMgtBaseResponse<IMgtSiteResponse>,
      ISiteInputParams
    >({
      async queryFn(
        arg: ISiteInputParams,
        { getState },
        extraOptions,
        fetchWithBQ
      ) {
        let url = `sites/${arg.siteId}/sites?${getUnEncryptedQueryString({
          ...arg
        })}`;
        let result: any = await fetchWithBQ({
          url: url
        });
        return result;
      }
    }),
    getDrives: builder.query<
      IMgtBaseResponse<IMgtSiteContentResponse>,
      IDriveInputParams
    >({
      query: (args: IDriveInputParams) => {
        const { siteId, params } = args;
        return {
          url: `sites/${siteId}/drives`,
          params
        };
      }
    }),
    getDriveItems: builder.query<
      IMgtBaseResponse<IMgtFolderResponse | IMgtFileResponse>,
      IDriveItemInputParams
    >({
      query: (arg: IDriveItemInputParams) => {
        const { siteId, driveId, params } = arg;
        return {
          url: `/sites/${siteId}/drives/${driveId}/root/children`,
          params
        };
      }
    }),
    getFolderItems: builder.query<
      IMgtBaseResponse<IMgtFolderResponse | IMgtFileResponse>,
      IFolderItemInputParams
    >({
      query: (arg: IFolderItemInputParams) => {
        const { siteId, driveId, itemId, params } = arg;
        return {
          url: `/sites/${siteId}/drives/${driveId}/items/${itemId}/children`,
          params
        };
      }
    })
  })
});

export const {
  useLazyGetAllSitesQuery,
  useLazyGetSitesQuery,
  useLazyGetDrivesQuery,
  useLazyGetDriveItemsQuery,
  useLazyGetFolderItemsQuery
} = microsoftApi;
