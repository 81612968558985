import { useCallback, useState } from 'react';
import { Tooltip } from '@mui/material';

function TooltipOnDisabled({ title, children, showTooltip }) {
  const [isTooltipOpen, setIsTooltipOpen] = useState<boolean>(false);

  const handleMouseEnter = useCallback(() => {
    if (showTooltip) {
      setIsTooltipOpen(true);
    }
  }, [showTooltip]);

  const handleMouseLeave = useCallback(() => {
    setIsTooltipOpen(false);
  }, []);

  return (
    <Tooltip title={title} open={isTooltipOpen}>
      <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        {children}
      </div>
    </Tooltip>
  );
}

export default TooltipOnDisabled;
