import { ArticleOutlined } from '@mui/icons-material';
import { initialColorList } from 'src/theme/customColors';
import { RESOURCE_TYPE } from 'src/types/enum';
import { CustomCollectionIcon, CustomFolderIcon } from '../styles';
import { getInitials } from 'src/utils/utils';
import { StringKeys } from 'src/types/base';

const WorkspaceIcon = ({ item }) => {
  return (
    <div
      style={{
        backgroundColor: item?.initials_color || initialColorList[0],
        border:
          !item?.initials_color ||
          item?.initials_color === initialColorList[0] ||
          item?.initials_color === '#000C57'
            ? '1px solid white'
            : 'none',
        borderRadius: '4px',
        height: '28px',
        width: '28px',
        fontSize: '12px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
      aria-haspopup="true"
    >
      {item?.initials || getInitials(item.display_name)}
    </div>
  );
};

const defaultItem = {
  icon: ArticleOutlined,
  link: '/data-library'
};

const getItemData = (item: StringKeys) => {
  let itemData;

  switch (item.code) {
    case RESOURCE_TYPE.WORKSPACE:
    case RESOURCE_TYPE.PUBLIC_WORKSPACE:
    case RESOURCE_TYPE.FOUND_WORKSPACE:
    case RESOURCE_TYPE.PRIVATE_WORKSPACE:
      itemData = {
        icon: () => <WorkspaceIcon item={item} />,
        link: `/data-library/collections/${item.item_id}`
      };
      break;
    case RESOURCE_TYPE.COLLECTION:
    case RESOURCE_TYPE.FOUND_COLLECTION:
      itemData = {
        icon: CustomCollectionIcon,
        link: `/data-library/resources/${item.item_id}`
      };
      break;
    case RESOURCE_TYPE.FOLDER:
      itemData = {
        icon: CustomFolderIcon,
        link: `/data-library/resources/${item.item_id}`
      };
      break;
    default:
      itemData = defaultItem;
  }
  return itemData;
};

export default getItemData;
