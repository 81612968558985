import React, { RefObject, useCallback, useEffect, useState } from 'react';
import {
  ButtonBase,
  Chip,
  Dialog,
  Paper,
  Stack,
  Typography
} from '@mui/material';
import ChipItem from './Item';

type Props = {
  maxNumber: number;
  data: { id: number; text: string }[];
  icon: React.ReactElement;
  dialogTitle?: string;
  size?: 'small' | 'medium';
  allowRemove?: boolean;
  onTagRemove?: (tagId: number) => void;
  disabled?: boolean;
  color?:
    | 'default'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning';
  parentWidth?: RefObject<HTMLDivElement>;
  staticOffset?: number;
  showMore?: boolean;
};

const StackedChips = (props: Props) => {
  const {
    data,
    maxNumber,
    dialogTitle = '',
    size = 'small',
    parentWidth,
    staticOffset = 0,
    showMore = true,
    ...rest
  } = props;

  const [showAllChips, setshowAllChips] = useState<boolean>(false);

  const openShowAllChipsDialog = useCallback(() => setshowAllChips(true), []);
  const closeShowAllChipsDialog = useCallback(() => setshowAllChips(false), []);
  const [maxChips, setMaxChips] = useState(maxNumber);

  const calculateChipsNumber = useCallback(() => {
    if (!data || !parentWidth?.current) return;
    const containerWidth = parentWidth.current.offsetWidth;
    let totalWidth = staticOffset;
    let maxChipsNumber = 0;
    const showMoreWidthAndMargins = 32;
    const chipIconWidthPadding = 26;
    for (const tag of data) {
      const chipContainer = document.createElement('div');
      chipContainer.style.display = 'inline-block';
      chipContainer.style.padding = '10px';
      parentWidth.current.appendChild(chipContainer);
      chipContainer.innerText = tag.text;
      const chipWidth = chipContainer.offsetWidth;
      parentWidth.current.removeChild(chipContainer);
      totalWidth += chipWidth + chipIconWidthPadding;
      if (totalWidth + showMoreWidthAndMargins <= containerWidth) {
        maxChipsNumber++;
      } else {
        break;
      }
    }
    setMaxChips(maxChipsNumber);
  }, [data, parentWidth]);

  useEffect(() => {
    if (data && parentWidth) calculateChipsNumber();
  }, [data, parentWidth?.current?.offsetWidth]);
  return (
    <>
      <Stack direction={'row'} flexWrap={'wrap'} height={'24px'} spacing={0.5}>
        {data.slice(0, maxChips).map((item) => (
          <ChipItem
            key={item.id}
            item={item}
            {...rest}
            size={size}
            disabled={props?.disabled || false}
            color={props.color}
          />
        ))}
        {!!(data.length > maxChips) && showMore && (
          <ButtonBase onClick={openShowAllChipsDialog}>
            <Chip
              label={`+${data.length - maxChips}`}
              key={'total-count'}
              color={props.color || 'primary'}
              sx={(theme) => ({
                '&:hover': {
                  cursor: 'pointer'
                }
              })}
              size={size}
            />
          </ButtonBase>
        )}
      </Stack>
      <Dialog open={showAllChips} onClose={closeShowAllChipsDialog}>
        <Paper
          sx={(theme) => ({
            padding: theme.spacing(3)
          })}
        >
          <Typography variant="h3">{dialogTitle}</Typography>
          {data.map((item) => (
            <Chip
              key={item.id}
              label={item.text}
              color={props.color}
              sx={(theme) => ({ margin: theme.spacing(0.5) })}
              icon={rest.icon}
            />
          ))}
        </Paper>
      </Dialog>
    </>
  );
};

export default StackedChips;
