import { Box, List, Typography } from '@mui/material';
import { useLocation, matchPath, useNavigate } from 'react-router-dom';
import { TFunction, useTranslation } from 'react-i18next';
import {
  CustomDivider,
  CustomStarRoundedIcon,
  MenuWrapper,
  SubMenuWrapper
} from '../styles';
import { RootState, useSelector } from 'src/redux/store';
import { useMemo } from 'react';
import SidebarFavouriteItem from './item';
import getFavouriteItems, { FavouriteItem, FavouriteItems } from './items';
import { makeStyles } from '@mui/styles';
import { PureLightTheme } from 'src/theme/schemes/PureLightTheme';
import { useDispatch } from 'react-redux';
import { startChat } from 'src/content/Documents/utils/gridUtils';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';

export const useStyles = makeStyles<typeof PureLightTheme>((theme) => ({
  container: {
    '&::-webkit-scrollbar': {
      width: '10px',
      backgroundColor: theme.colors.primary.main
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'darkgrey',
      borderRadius: '10px'
    }
  }
}));

const renderSidebarFavouriteItems = ({
  item,
  path,
  handleStartChatClick,
  t
}: {
  item: FavouriteItem;
  path: string;
  handleStartChatClick: (
    id: number,
    name: string,
    workspaceName?: string
  ) => void;
  t: TFunction<'translation', undefined>;
}): JSX.Element => {
  const key = item.name;

  const exactMatch = item?.link
    ? !!matchPath(
        {
          path: item?.link,
          end: true
        },
        path
      )
    : false;

  return (
    <SubMenuWrapper>
      <List component="div">
        {[
          <SidebarFavouriteItem
            key={key}
            active={exactMatch}
            name={item.name}
            workspaceName={item?.workspaceName}
            link={item?.link}
            icon={item?.icon}
            id={item.id}
            handleStartChatClick={handleStartChatClick}
            t={t}
          />
        ]}
      </List>
    </SubMenuWrapper>
  );
};

function Favourites() {
  const location = useLocation();
  const { t }: { t: TFunction<'translation', undefined> } = useTranslation();
  const classes = useStyles();
  const { userAppPermissions, userFavourites } = useSelector(
    (state) => state.data
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { selectedChat } = useSelector((state: RootState) => state.chat);

  const menuItems = useMemo(
    () => getFavouriteItems(userAppPermissions, userFavourites || []),
    [userFavourites]
  );

  const listHeight = useMemo(
    () =>
      `${
        userFavourites?.length && userFavourites.length <= 4
          ? userFavourites.length * 44.5
          : 178
      }px`,
    [userFavourites]
  );

  const handleStartChatClick = async (
    id: number,
    name: string,
    workspaceName?: string
  ) => {
    const routeState = location?.state?.documentContext;
    await startChat({
      documentContext: {
        id,
        name,
        workspaceName
      },
      dispatch,
      navigate,
      selectedChat,
      routeState
    });
  };

  return (
    <>
      <CustomDivider />
      <Box height={'40px'} display={'flex'} padding={'10px 8px'}>
        <CustomStarRoundedIcon
          style={{
            fontSize: 16,
            color: 'white'
          }}
        />
        <Typography fontSize={'14px'} color={'white'} paddingLeft={'6px'}>
          {t(T.favourites)}
        </Typography>
      </Box>
      <Box height={listHeight}>
        <Box
          position={'absolute'}
          height={listHeight}
          width={'100%'}
          className={classes.container}
          sx={{
            overflowY: 'auto'
          }}
        >
          {menuItems.map((section: FavouriteItems) => (
            <MenuWrapper key={section?.item?.link}>
              <List component="div">
                {renderSidebarFavouriteItems({
                  item: section.item,
                  path: location.pathname,
                  handleStartChatClick: handleStartChatClick,
                  t: t
                })}
              </List>
            </MenuWrapper>
          ))}
        </Box>
      </Box>
    </>
  );
}

export default Favourites;
