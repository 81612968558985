import { useContext } from 'react';
import { IconButton, Typography, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_CONSTANTS } from 'src/utils/translations';
import { ReactComponent as SidebarIcon } from 'src/assets/sidebarIcon/sidebar-icon.svg';
import { SidebarContext } from 'src/contexts/SidebarContext';
import { RootState, useDispatch, useSelector } from 'src/redux/store';
import {
  closeDocumentViewer,
  createChatSnapshot,
  setSelectedChat
} from 'src/redux/slices/chat';
import { Add } from '@mui/icons-material';

const CreateChat = () => {
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();
  const { toggleSidebar } = useContext(SidebarContext);
  const { selectedChat } = useSelector((state: RootState) => state.chat);
  const dispatch = useDispatch();
  const { state: routeState } = useLocation();

  const handleCreateNewChat = () => {
    if (selectedChat !== 0 || routeState) {
      dispatch(closeDocumentViewer(selectedChat));
      dispatch(setSelectedChat(undefined));
      dispatch(createChatSnapshot({ chatId: 0 }));
      setTimeout(() => {
        dispatch(setSelectedChat(0));
      }, 100);
    }
  };

  return (
    <Box
      sx={{
        margin: theme.spacing(1.5),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Button
        component={NavLink}
        variant="contained"
        color="secondary"
        sx={{
          borderRadius: '6px',
          width: 'calc(100% - 48px)'
        }}
        to="/chat"
        onClick={handleCreateNewChat}
        size="large"
        startIcon={<Add />}
      >
        <Typography variant="h4">
          {t(TRANSLATION_CONSTANTS.createNewChat)}
        </Typography>
      </Button>

      {toggleSidebar && (
        <IconButton
          sx={{
            marginLeft: '8px',
            border: `1px solid ${theme.colors.secondary.main}`,
            padding: theme.spacing(1.4)
          }}
          color="secondary"
          onClick={toggleSidebar}
        >
          <SidebarIcon height={'16px'} width={'16px'} />
        </IconButton>
      )}
    </Box>
  );
};

export default CreateChat;
