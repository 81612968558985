import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta
} from '@reduxjs/toolkit/dist/query';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { IS_ENCRYPTION_ENABLED } from 'src/config';
import { getEncryptParams } from 'src/keycloak';
import { StringKeys } from 'src/types/base';
import {
  getParam,
  getPayload,
  getChallengeHeader,
  decrypt3
} from 'src/utils/encryption';

export type Builder = EndpointBuilder<
  BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError,
    {},
    FetchBaseQueryMeta
  >,
  APITags,
  'api'
>;

export type APITags =
  | 'User'
  | 'Files'
  | 'Chats'
  | 'Tags'
  | 'ChatParams'
  | 'Roles'
  | 'Permissions'
  | 'IdentityProvider'
  | 'Groups'
  | 'ResourceProvider'
  | 'Favourites';

export const getQueryString = (params: any) => {
  let querystring = ``;
  let updatedParams = removeNullKeys({ ...params });
  Object.keys(updatedParams).forEach((key) => {
    querystring = querystring + `${key}=${getParam(updatedParams[key], true)}&`;
  });

  if (IS_ENCRYPTION_ENABLED) {
    querystring = `${querystring}payload=${btoa(
      getPayload('', getEncryptParams())
    )}`;
  } else {
    querystring = querystring.slice(0, -1);
  }
  return querystring;
};

export const getUnEncryptedQueryString = (params: StringKeys) => {
  let querystring = ``;
  const updatedParams: StringKeys = removeNullKeys({ ...params });
  Object.keys(updatedParams).forEach((key) => {
    querystring = querystring + `${key}=${updatedParams[key] as string}&`;
  });

  querystring = querystring.slice(0, -1);

  return querystring;
};

export const appendFormData = (params: any) => {
  const encryptionParams = getEncryptParams();
  let formData = new FormData();
  Object.keys(params).forEach((key) => {
    let value = params[key];
    let finalVal = value && typeof value === 'object' ? `${[value]}` : value;
    formData.append(key, getParam(finalVal));
  });

  if (IS_ENCRYPTION_ENABLED) {
    formData.append('payload', getPayload('', encryptionParams));
  }

  return formData;
};

export function removeNullKeys(obj) {
  for (let key in obj) {
    if (obj[key] === undefined || obj[key] === '' || obj[key] === null) {
      delete obj[key];
    }
  }
  return obj;
}

export const getHeaders = (url: string, data?: any) => {
  if (IS_ENCRYPTION_ENABLED) {
    const encryptionParams = getEncryptParams();
    return {
      headers: {
        Channel: getChallengeHeader(encryptionParams, url, data)
      }
    };
  }
  return {};
};

export const decryptResponse = (response, decryptKey = undefined) => {
  try {
    if (IS_ENCRYPTION_ENABLED) {
      if (response.data) {
        let decrypted = decrypt3(
          decryptKey || getEncryptParams()?.k,
          response.data
        );
        return JSON.parse(decrypted);
      } else if (response.error) {
        let decrypted = decrypt3(
          decryptKey || getEncryptParams()?.k,
          response?.error?.data
        );
        return { ...JSON.parse(decrypted) };
      } else {
        return undefined;
      }
    }
    return response.data
      ? response.data
      : { ...(response?.error?.data || response) };
  } catch (err) {
    return undefined;
  }
};

export const serializeErrorResponse = (response: {
  code: number;
  msg: string;
  extended: { error_name: string; args: string; msg: string };
}): { error: FetchBaseQueryError } => ({
  error: {
    status: (response?.extended?.error_name || 'CUSTOM_ERROR') as any,
    originalStatus: response?.code,
    data: response?.extended?.args,
    error: response?.extended?.msg || response?.msg
  }
});
