import { styled } from '@mui/material';

export const drawerWidth = 300;
export const miniDrawerWidth = 60;
const marginLeft = drawerWidth - miniDrawerWidth;
interface MainProps {
  props?: {
    isSideBarOpen?: boolean;
    isHidden?: boolean;
  };
}

export const Main = styled('main')<MainProps>(
  ({ theme, props: { isSideBarOpen, isHidden } }) => ({
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginLeft: '0',
    display: 'flex',
    width: '100%',
    maxWidth: isHidden ? '100%' : `calc(100% - ${drawerWidth}px)`,
    ...(isSideBarOpen && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      }),
      maxWidth: `calc(100% - ${miniDrawerWidth}px)`,
      marginLeft: `-${marginLeft}px`
    })
  })
);
